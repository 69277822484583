import * as React from "react";
import { DashboardSection, FeaturedList, Button, AlertList } from "@coinlist/frontend-ui-lib"
require("@coinlist/karma-frontend/dist/style.css");

export default function Dashboard(props) {
    return (
        <div className="uilib-flex uilib-flex-col uilib-gap-6 uilib-flex-1">
            {props.showAdminButton && <Button isFullWidth={false} isDark style={{ alignSelf: "flex-start" }} onClick={() => window.location.href = "/admin/dashboard_notifications"}>Add offer notification</Button>}
            <AlertList limit={1} locale={props.locale} items={props.alertNotifications.items} />
            <FeaturedList limit={1} locale={props.locale} items={props.tokenLaunchNotifications.items} />
            <DashboardSection limit={5} locale={props.locale} title={props.testnetRewardsNotifications.title} subtitle={props.testnetRewardsNotifications.subtitle} items={props.testnetRewardsNotifications.items} />
            <DashboardSection hasTwoCols locale={props.locale} title={props.activeNotifications.title} items={props.activeNotifications.items} />
            <DashboardSection isCard locale={props.locale} title={props.nowListingNotifications.title} subtitle={props.nowListingNotifications.subtitle} items={props.nowListingNotifications.items} />
        </div>
    );
}

import * as React from "react";
import { useState } from "react";

import Box from "@react/components/Box";
import { Button } from "@react/components";
import Flex from "@react/components/Flex";
import { Link } from "@react/components";
import { TextInput, Field, Select, Selectize, Label } from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import InputWithAddon from "@react/views/shared/forms/InputWithAddon/InputWithAddon";
import Loading from "@react/components/Loading";
import Typography from "@react/components/typography/Typography";

import { SelectOption } from "@react/types/ui-component";
import { truncateFloat } from "@react/utils";
import { FLEX_TITLE_STYLE } from "./Submit";
import { LOGO_MAP } from "@react/shared/logos";
import { RequestType } from "@react/utils/network";

const BOX_PADDING = 32;
export const DECIMAL_PLACES = 6;

const findSelectedEntity = (entitySelectOptions, slug) => {
  return entitySelectOptions.find((option) => option.slug === slug);
};

const createSelectizeOptions = (conversionPairs) => {
  return conversionPairs.map((pair) => ({
    labelText: pair.value,
    labelHtml: (
      <Flex alignItems="center" style={{ opacity: pair.enabled ? 1 : 0.5, filter: pair.enabled ? "none" : "grayscale(100%)" }} container>
        <img
          src={LOGO_MAP[pair.base_asset_symbol.toLowerCase()]}
          style={{ height: 20, width: 20 }}
        />
        <div style={{ paddingLeft: 4 }}>{pair.base_asset_symbol}</div>
        <i
          className={`icon-right`}
          style={{ fontSize: 20, paddingLeft: 12, paddingRight: 12 }}
        ></i>
        <img
          src={LOGO_MAP[pair.counter_asset_symbol.toLowerCase()]}
          style={{ height: 20, width: 20 }}
        />
        <div style={{ paddingLeft: 4 }}>{pair.counter_asset_symbol}</div>
      </Flex>
    ),
    value: pair.value,
  }));
};

interface ConvertProps {
  amount: string;
  assetToConvert: any;
  assetSelectOptions: Array<SelectOption>;
  conversionPairs: Array<any>;
  entityId: string;
  entitySelectOptions: Array<SelectOption>;
  entitySlug: string;
  incrementPage: () => void;
  minAmountsMap: any;
  setAmount: (amount) => void;
  setAmountToDeliver: (amount) => void;
  setAssetToConvert: (value) => void;
  setFee: (fee) => void;
  walletMap: any;
}

export default function Convert(props: ConvertProps) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(() =>
    findSelectedEntity(props.entitySelectOptions, props.entitySlug)
  );
  const [selectOptions, setSelectOptions] = useState(() =>
    createSelectizeOptions(props.conversionPairs)
  );

  const hasErrors = () => {
    const amountFloat = parseFloat(props.amount);
    const baseAssetSymbol = props.assetToConvert.base_asset_symbol.toLowerCase();
    if (isNaN(amountFloat)) {
      setError("Please enter a valid number");
      return true;
    }
    if (amountFloat === 0) {
      setError("Please enter a value greater than 0");
      return true;
    }
    if (
      amountFloat >
      truncateFloat(
        props.walletMap[props.assetToConvert.base_asset_symbol].balance
      )
    ) {
      setError("Please enter a value less than or equal to your balance");
      return true;
    }
    if (
      props.minAmountsMap[baseAssetSymbol] &&
      amountFloat < props.minAmountsMap[baseAssetSymbol]
    ) {
      setError(
        `The minimum conversion amount is ${props.minAmountsMap[baseAssetSymbol]
        } ${baseAssetSymbol.toUpperCase()}`
      );
      return true;
    }
    return false;
  };

  return (
    <Box style={{ position: "relative" }}>
      <Flex container spacing={2}>
        <Flex item style={FLEX_TITLE_STYLE} xs={24}>
          <Typography bold>Convert</Typography>
        </Flex>
        <Flex item style={{ paddingLeft: 24, paddingRight: 24 }} xs={24}>
          <Field>
            <Label>Convert</Label>
            <Selectize
              onChange={(value) => {
                const asset = props.assetSelectOptions.find(
                  // @ts-ignore
                  (option) => option.value === value
                );
                props.setAssetToConvert(asset);
                props.setAmount("");
                setError("");
              }}
              options={selectOptions}
              size={"large"}
              value={props.assetToConvert.value}
            />
          </Field>
        </Flex>
        <Flex
          item
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: BOX_PADDING * 4,
          }}
          xs={24}
        >
          <label
            className={`c-label s-fontSize12 ${error ? "u-colorRed" : ""}`}
          >
            Amount&nbsp;&middot;&nbsp;
            <Link
              disabled={props.assetToConvert.enabled ? false : true}
              onClick={() => {
                const val = truncateFloat(
                  props.walletMap[props.assetToConvert.base_asset_symbol]
                    .balance
                );
                props.setAmount(val === 0 ? "0" : val);
              }}
            >
              Max
            </Link>
          </label>
          <BlockField
            error={error}
            hint={props.assetToConvert.enabled ? `You have ${truncateFloat(
              props.walletMap[props.assetToConvert.base_asset_symbol].balance
            )} ${props.assetToConvert.base_asset_symbol} available to convert` : ""}
          >
            <InputWithAddon
              addon={props.assetToConvert.base_asset_symbol}
              position="right"
            >
              <TextInput
                disabled={props.assetToConvert.enabled ? false : true}
                size="large"
                onChange={(e) => {
                  const newAmount = (isNaN(parseFloat(e.currentTarget.value)) || isNaN(e.currentTarget.value.replace(/,/g, '') as any))
                    ? ""
                    : e.currentTarget.value.replace(/,/g, '');
                  props.setAmount(newAmount);
                }}
                placeholder="1.0123"
                value={props.amount}
              />
            </InputWithAddon>
          </BlockField>
          <Typography style={{ color: "red", display: props.assetToConvert.enabled ? "none" : "inline" }}>{props.assetToConvert.base_asset_symbol} to {props.assetToConvert.counter_asset_symbol} conversions are temporarily disabled</Typography>
        </Flex>
      </Flex>
      <Button
        disabled={props.assetToConvert.enabled ? false : true}
        onClick={() => {
          if (!hasErrors()) {
            if (props.assetToConvert.value === "nu_to_t") {
              props.incrementPage();
            } else {
              setLoading(true);
            }
          }
        }}
        style={{
          bottom: BOX_PADDING,
          height: 46,
          right: BOX_PADDING,
          position: "absolute",
          width: 105,
        }}
      >
        {loading ? (
          <Loading
            handleResponse={(response) => {
              setLoading(false);
              if (response.status === 200 && response.data) {
                const { amount_to_deliver, fee_percent } = response.data;
                props.setFee(fee_percent);
                props.setAmountToDeliver(parseFloat(amount_to_deliver));
                props.incrementPage();
                return;
              }
              alert(
                "Error fetching quote for your conversion, please refresh the page and try again."
              );
            }}
            loading={loading}
            requestData={{
              data: {
                amount: props.amount,
                base_asset_symbol: props.assetToConvert.base_asset_symbol,
                counter_asset_symbol: props.assetToConvert.counter_asset_symbol,
                customer_wallet_id:
                  props.walletMap[props.assetToConvert.base_asset_symbol].id,
                entity_id: props.entityId,
              },
              type: RequestType.GET,
              url: props.assetToConvert.mint_url,
            }}
            spinnerColor="white"
          />
        ) : (
          "Review"
        )}
      </Button>
    </Box>
  );
}
